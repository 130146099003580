import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import BlogListing from '../../components/BlogListing'

const BlogTag = props => {
  const tag = props.data.craftGql.tags[0]
  const entries = props.data.craftGql.entries

  return (
    <Layout>
      <SEO
        title={tag.title}
        description={`${tag.title} Posts`}
        additionalMeta={[{ name: `robots`, content: `noindex,follow` }]}
      />
      <section role="main" className={`pt-4 mb-32`}>
        <h1
          className={`text-5xl font-bold my-12 max-w-md mx-auto leading-none`}
        >
          {`${tag.title} Posts`}
        </h1>
        {entries.map((entry, index) => (
          <BlogListing
            title={entry.title}
            uri={entry.uri}
            date={entry.postDate}
            first={index === 0}
            commentsEnabled={entry.commentsEnabled}
            key={index}
          />
        ))}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $search: String!) {
    craftGql {
      tags(group: "blogTags", slug: [$slug], limit: 1) {
        title
        slug
      }
      entries(section: "blog", search: $search) {
        title
        uri
        postDate
        ... on CraftGQL_blog_blog_Entry {
          commentsEnabled
        }
      }
    }
  }
`

export default BlogTag
