import React from 'react'
import { Link } from 'gatsby'
import dateformat from 'dateformat'
import { widont } from '../utils/text'

const BlogListing = ({ title, uri, date, commentsEnabled, first = false }) => {
  return (
    <div
      className={
        `max-w-md mx-auto py-4 md:py-6 md:items-center md:flex` +
        (!first ? ` border-t border-soft-white` : ``)
      }
    >
      <h3 className={`w-full font-bold py-0 md:py-3 md:pr-4 md:w-2/3`}>
        <Link to={`/${uri}`} className={`text-3xl leading-none text-charcoal`}>
          {widont(title)}
        </Link>
      </h3>
      <div className={`w-full block md:w-1/3`}>
        <p
          className={
            `text-light-slate text-sm font-sans` /* + {%- if post.commentsEnabled == false %} comments-disabled{% endif %}*/
          }
        >
          <span className={`inline md:block`}>
            {dateformat(date, `mmmm d, yyyy`)}
          </span>
          {commentsEnabled && (
            <Link
              to={`/${uri}#comments`}
              className={`float-right inline ml-4 md:ml-0 md:block md:float-none`}
              data-disqus-url={`https://workingconcept.com/${uri}`}
            >
              Comments
            </Link>
          )}
        </p>
      </div>
    </div>
  )
}

export default BlogListing
